/*
The function call_verification_api provides the interface to our internal Verification API. 
input parameters:
  param = {
      image_person: picture of person in base64 encoding 
      image_id: picture of id card in base64 encoding 
    };

returns: 
 {
       "verificationStatus": "failed" or "passed" This variable shows if the verification process was completed or not. For example the verificationStatus can be "failed" in case any of the pictures did not contain face of a person.
       In case of "failed" ignore the rest of the outputs.
       "verificationResult": The actual result of verification process. 
       True if the person and id person are computed to be same. 
       False if they are not.
       "boundingBoxPerson" :  Bounding box around face in the person image
       "boundingBoxId":   Bounding box around face in the person image
       "extractedText": List of all the text found in the id image.
 }

*/


const base_url = "https://ye1r7h9smj.execute-api.eu-central-1.amazonaws.com/"
const trial_api_key = "gTFtsPdjt30sFmB3f4975TXiEiNTlFF9cJYwHT1f"

   
   export async function call_verification_api(image_person, image_id) {

    const body = {
      image_person: image_person,
      image_id: image_id
    }
      
    // Link to the api enpoint that needs to be called for verification
        const verification_rest_api_resource =  base_url + "Beta/verification/student-id";
        const response = await fetch(verification_rest_api_resource, {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
            "x-api-key": trial_api_key
          },
        });       
        const response_object = await response.json()
      
        return response_object;
      };
