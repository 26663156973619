import React, { useState } from "react";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Webcam from "react-webcam";
import rectangle_overlay from "../images/rectangle_overlay_3.png";
import Overlay from "./overlay.js"

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1000,
  },

  main: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 0",

  },

  gridItem: {
    flex: 1,
  },
  button: {
    marginBottom: "5px",
    textTransform: "none",
  },
}));

const videoConstraints = {
  height: 720,
  width: 1280,
  facingMode: "user",
};

//Component for the WebcamUpload window
const IdUpload = (props) => {

  const classes = useStyles();

  //state variable to store image from webcam
  const [imageWebcam, setImageWebcam] = useState("none");
  //state variable to check if image is taken or not
  const [isImageTaken, setisImageTaken] = useState(false);

   //Used to refer to the webcam element
  const webcamRef = React.useRef(null);

  // reference element to refer to the image element
  const imgElement = React.useRef(null);

  //Callback function to take image and store it in the state variable
  const captureScreenshot = () => {
    const screenshot = webcamRef.current.getScreenshot();
    //Save screenshot taken to state variable to display it.
    setImageWebcam(screenshot);
    //Toggle the state to show the image instead of the webcam component
    setisImageTaken(!isImageTaken);

    //unencode image bytes from base64encoding to ArrayBuffer (Bytes Array) for  AWS Rekognition DetectFaces API
    let image = atob(screenshot.split("data:image/jpeg;base64,")[1]);

    let length = image.length;
    let imageBytes = new ArrayBuffer(length);
    let ua = new Uint8Array(imageBytes);
    for (var i = 0; i < length; i++) {
      ua[i] = image.charCodeAt(i);
    }
    //pass on imageBytes to the App parent element, from where its sent to the API when the compare button is clicked
    props.callApp([imageBytes, screenshot]);
   
  };
  return (
    <React.Fragment>
      <Grid container className={classes.main}>
        <Grid item className={classes.gridItem}>
          {isImageTaken === false ? (
            <div style={{ position: "relative", padding: "10px" }}>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                forceScreenshotSourceSize
                
                videoConstraints={videoConstraints}
                screenshotQuality={1}
                style={{
                  width: "auto",
                  maxHeight: "300px",
                  minHeight: "200px",
                }}
              />
              <Overlay src={rectangle_overlay} />
            </div>
          ) : (
                           
                  <img
                    src={imageWebcam}
                    ref={imgElement}
                    alt=" from local storage"
                    style={{
                      width: "auto",
                      maxHeight: "300px",
                      minHeight: "200px",
                    }}
                  ></img>
               
      
          )}
        </Grid>
        <Grid item className={classes.gridItem}>
         
          <Typography
            variant="body2"
            color="textSecondary"
            style={{
              margin: "auto",
              padding: "1em",
            }}
            component="p"
          >
            Take a picture of your Student ID Card. Bring the card close to the
            camera and keep it inside the highlighted area. Then press the "Take
            Picture" button.
          </Typography>
        </Grid>

        <Grid item className={classes.button}>
          {isImageTaken === false ? (
            <Button
              variant="contained"
              key={1}
              color="primary"
              component="label"
              disabled={false}
              margin="auto"
              onClick={captureScreenshot}
              className={classes.button}
            >
              Take Picture
            </Button>
          ) : (
            <div>
              <Button
                variant="contained"
                component="label"
                disabled={false}
                onClick={() => setisImageTaken(!isImageTaken)}
                margin="auto"
                style={{
                  margin: "2px 30px",
                  textTransform: "none",
                }}
              >
                Take another picture
              </Button>

              <Button
                variant="contained"
                key={1}
                color="primary"
                component="label"
                margin="auto"
                disabled={false}
                onClick={props.nextStep}
                style={{
                  margin: "2px 30px",
                  textTransform: "none",
                }}
              >
                Next
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default IdUpload;
