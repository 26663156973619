import React from "react";
import logo from "./cubemos_logo_final.png";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: "center",
    color: "#1e3b95"
  }
}));

function Header() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div style={headerStyle}>
      <img
        style={{ position: "absolute", left: "20px", top: "0" }}
        src={logo}
        height={"60"}
        width={"auto"}
        alt="Cubemos logo"
      />
     
 
      <Typography
        gutterBottom
        variant="h4"
        component="h1"
        className={classes.title}
              //style={{ position: "absolute",paddingLeft:"40%",top: "0" }}
        align="center"
      >
        Identity Verification
      </Typography>
      </div>
        {/* <img
        style={{ position: "absolute", left: "20px", top: "0" }}
        src={logo}
        height={"50"}
        width={"auto"}
        alt="Cubemos logo"
      /> */}
      
     
    </React.Fragment>

    // <React.Fragment>
    //   <CssBaseline />
    //   <AppBar position="relative">
    //     <Toolbar
    //       style={{
    //         background: "#1e3b95",
    //         display: "flex",
    //         justifyContent: "space-between"
    //       }}
    //     >
    //       {/* // <CameraIcon className={classes.icon} /> */}
    //       <Typography variant="h5" color="inherit" >
    //         Indentity Verification
    //       </Typography>
    //       <Typography  variant="h5" color="inherit" style={{paddingRight:"40px"}}>
    //        Cubemos
    //       </Typography>

    //     </Toolbar>
    //   </AppBar>
    // </React.Fragment>
  );
}

const headerStyle = {
  background: "#fff",
  borderBottom: "2px solid #1e3b95",
  color: "#333",
  align: "middle", 
  height: "50px",
  padding: "0px 0px",
  margin: "0 0"
};

const linkStyle = {
  color: "#fff",
  textDecoration: "none"
};

export default Header;
