import React, { useState } from "react";
import Header from "./components/layout/header";
import "./App.css";
import IdUpload from "./components/pages/idUpload";
import FaceUpload from "./components/pages/faceUpload";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import logo from "./components/layout/cubemos_logo_final.png";
import Link from "@material-ui/core/Link";
import HorizontalStepper from "./components/pages/horizontalStepper";
import VerifyPage from "./components/pages/verifyPage";
import {call_verification_api} from "./interfaceVerificationApi.js"



function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://cubemos.com/">
        Cubemos
      </Link>{" "}
      {new Date().getFullYear()}
      <br />
      <Link color="inherit" href="https://www.cubemos.com/data-protection/">
        Data Protection
      </Link>
      {"  |  "}
      <Link color="inherit" href="https://www.cubemos.com/general-terms/">
        Terms and conditions
      </Link>
      {"  |  "}
      <Link color="inherit" href="https://www.cubemos.com/legal-notice/">
        Legal Notice
      </Link>
    </Typography>
  );
}

const verification_rest_api =
  "https://ye1r7h9smj.execute-api.eu-central-1.amazonaws.com/Beta/verification/student-id";

//For using amazon rekognition api
var AWS = require("aws-sdk");
// Initialize the Amazon Cognito credentials provider

// You need the AWS credentials to run this demo. Contact @Patrick or @Paul to get the credentials.

// Initialize the Amazon Cognito credentials provider
AWS.config.region = "eu-central-1"; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: "eu-central-1:befefe77-ccae-4529-add4-f29e8040aa30",
});

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    flexWrap: "nowrap",
    paddingTop: theme.spacing(2),
    justifyContent: "center",
  },
  title: {
    textAlign: "center",
    color: "#1e3b95",
  },
  result: {
    textAlign: "center",
    color: "#525252",
    paddingLeft: "15px",
    paddingTop: "5px",
    paddingBottom: "5px",
  },

  buttonSubmit: {
    marginBottom: "10px",
    marginTop: "5px",
  },

  input: {
    //height: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
  },
  gridItem: {
    flex: 1,
    paddingTop: "10px",
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(10),
    //For placing the footer at the bottom
    // position:"absolute",
    // left: "0",
    // bottom:"0",
    // right:"0"
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 10px",
  },
}));

function App() {
  const classes = useStyles();
  //State variable for storing image of ID card
  const [imageIDcard, setimageIDcard] = useState("none");
  //State variable for storing image of person
  const [imagePerson, setimagePerson] = useState("none");

  //State for holding the current step in the verification process
  const [step, setStep] = useState(0);
  //State for storing bounding boxes and passing them onto the components
  const [bBoxes, setBBoxes] = useState([[null], [null]]);

  //State for storing the text extracted from ID card picture
  const [extractedText, setExtractedText] = useState(null);

  //State for changing display color of background based on verification success or failure
  const [resultBorder, setResultBorder] = useState("#f2f2f2");
  //State for storing the output text in case of verified or not verified face
  const [resultText, setResultText] = useState(
    "Press the button to verify your identity"
  );

  // "Verification Success. The two faces match.
  // "Verification Fail. The two faces dont match."
  const facesMatchPass = "The two persons are the same.";
  const facesMatchFail = "The two persons are not the same.";

  //Callback function called by verfiyPage when retry button is clicked. This function resets the state of the application to the first step.
  const onRetry = () => {
    setStep(0);
    setResultText("Press the button to verify your identity");
    setResultBorder("#f2f2f2");
    setBBoxes([[null], [null]]);
    setExtractedText(null);
  };

  //Function to store the base64 image recieved from idUpload component
  const onIdUpload = (imageFromIdUpload) => {
    setimageIDcard(imageFromIdUpload);
  };
  //Function to store the base64 image recieved from faceUpload component

  const onFaceUpload = (imageFromFaceUpload) => {
    setimagePerson(imageFromFaceUpload);
  };

  //Callback function which is called when next button is clicked. It forwards the state of the step variable to the next step in verification process.
  const nextStep = () => {
    setStep(String(Number(step) + 1));
  };

  // Callback function to call the Amazon facecompare api and also calls the Amazon textract api on the id card image
  const onSubmit = async () => {


    // Send the images as base64 encoded strings to the verification api with the following structure
    
      const image_person= imagePerson[1]
      const image_id= imageIDcard[1]
  


    // call_verification_api function takes care of call to the verification api and returns the result of verification and text extracted from id card in an object:
//    response: {
//         "verificationStatus": "failed" or "passed" This variable defines if the verification process was completed or not.
//         "verificationResult": The actual result of verification process. True if the person and id person are computed to be same. False if they are not.
//         "boundingBoxPerson" :  Bounding box around face in the person image
//         "boundingBoxId":   Bounding box around face in the person image
//         "extractedText": List of all the text found in the id image.
// }
    const response = await call_verification_api(image_person,image_id );

    let verificationStatus = response["verificationStatus"]

    if (verificationStatus === "failed"){
      setResultText("Verification Result: " + facesMatchFail)
      window.alert("Invalid images(s) - please try again with better images.")
      onRetry()
      //exit
      return;
    }

    let verificationResult = response["verificationResult"];

    let bBoxPerson = [
      response["boundingBoxPerson"]["Left"],
      response["boundingBoxPerson"]["Top"],
      response["boundingBoxPerson"]["Width"],
      response["boundingBoxPerson"]["Height"],
    ];

    let bBoxId = [
      response["boundingBoxId"]["Left"],
      response["boundingBoxId"]["Top"],
      response["boundingBoxId"]["Width"],
      response["boundingBoxId"]["Height"],
    ];

    /* Commenting out extracted_text since the text recieved from the api is not cleaned. include this after cleaned text is presented by the verification api
    let extracted_text = response["extractedText"];
    setExtractedText(extracted_text);
*/
    setBBoxes([bBoxPerson, bBoxId]);

    

    if (verificationResult === true) {
      setResultText("Verification Result: " + facesMatchPass)
    } 
    else {
      setResultText("Verification Result: " + facesMatchFail)
    }

  };

  return (
    <React.Fragment>
      <Header />

      <Container maxWidth="sm" className={classes.main}>
        <Grid container className={classes.main} spacing={4}>
          <Grid item>
            <Container
              style={{
                backgroundColor: "#f2f2f2",
                width: "100%",
                maxWidth: "1200px",
              }}
            >
              <Grid container className={classes.input}>
                <Grid item className={classes.gridItem}>
                  {
                    {
                      "0": (
                        <FaceUpload
                          callApp={onFaceUpload}
                          nextStep={nextStep}
                          bBox={bBoxes[0]}
                        ></FaceUpload>
                      ),
                      "1": (
                        <IdUpload
                          callApp={onIdUpload}
                          nextStep={nextStep}
                          bBox={bBoxes[1]}
                        ></IdUpload>
                      ),
                      "2": (
                        <VerifyPage
                          verify={onSubmit}
                          retry={onRetry}
                          images={[imagePerson[1], imageIDcard[1]]}
                          bBox={bBoxes}
                          extractedText={extractedText}
                          resultText={resultText}
                          resultBorder={resultBorder}
                        ></VerifyPage>
                      ),
                    }[step]
                  }
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Container>

      <HorizontalStepper step={step}></HorizontalStepper>
      <footer className={classes.footer}>
        <img
          style={{ display: "block", margin: "0 auto" }}
          src={logo}
          height={"40"}
          width={"auto"}
          align="center"
          alt="Cubemos logo"
        />
        <Copyright />
      </footer>
    </React.Fragment>
  );
}

export default App;
