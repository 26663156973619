import React from "react";



const Overlay = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "0",
        right: "0",
        bottom: "0",
        left: "0",
        color: "#fff",
        textAlign: "center",
      }}
    >
      <img alt ="Overlay" style={{ height: "100%" }} src={props.src} />
    </div>
  );
};

export default Overlay;
