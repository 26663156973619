import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Take a picture of your face', 'Take a picture of your ID', 'Review and Verify'];
}

const HorizontalStepper = (props) =>{
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(props.step);
  const steps = getSteps();
 
  React.useEffect(() => {
    
    if( props.step>=1)
    {
      handleNext()
    }
    else
    {
      setActiveStep(props.step)
    }
     
}, [props.step])


  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <div className={classes.root}>
      <Stepper key={props.step} activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper> 
     
    </div>
  );
}

export default HorizontalStepper;