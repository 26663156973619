import React, { useState } from "react";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Boundingbox from "react-bounding-box";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
  
  },

  main: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  imagesContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-evenly",
  
  },

  image: {
  
    maxWidth: "350px",
    margin: "1em",
  },

  gridItem: {
    flex: 1,
  },

  button: {
 
    margin: "auto",
    marginBottom: "0.5em",
    textTransform: "none",
  
  },
}));

//Component for the VerifyPage window
const VerifyPage = (props) => {
  const classes = useStyles();

 
  // State variable to store dimensions of image.[ height, width]
  const [dimensionsFaceImage, setDimensionsFaceImage] = useState([null, null]);
  // State variable to store dimensions of image.[ height, width]
  const [dimensionsIdImage, setDimensionsIdImage] = useState([null, null]);

  //Options for bounding box component
  const params = {
    image: "http://i.imgur.com/gF7QYwa.jpg",
    boxes: [],
    options: {
      colors: {
        //  normal: "rgba(124,252,0,1)",
        normal: "rgba(254, 211, 48,1.0)", //Taxi Yellow
        selected: "rgba(0,0,0,1)",
        unselected: "rgba(100,100,100,1)",
      },
      style: {
        maxWidth: "350px",
        margin: "1em",
      },
    },
  };

  // reference element to refer to the image element
  const faceImgElement = React.useRef(null);
  const idImgElement = React.useRef(null);

  // Get the height and width when image is loaded and store it in state. This is later on used to draw the bounding boxes on detected face
  const onFaceImgLoad = () => {
   
    setDimensionsFaceImage([
      faceImgElement.current.naturalHeight,
      faceImgElement.current.naturalWidth,
    ]);
  };

  // Get the height and width when image is loaded and store it in state. This is later on used to draw the bounding boxes on detected face
  const onIdImgLoad = () => {

    setDimensionsIdImage([
      idImgElement.current.naturalHeight,
      idImgElement.current.naturalWidth,
    ]);
  };



  return (
    <React.Fragment>
      <Grid container className={classes.main}>
        <Grid item>
          {
            //If props.bbox is null then show simple image element, if not then draw the bbox on the image
            //using the BoundingBox component.
            props.bBox[0][0] === null ? (
              <Grid container className={classes.imagesContainer}>
                <Grid item className={classes.gridItem}>
                  <img
                    src={props.images[0]}
                    onLoad={onFaceImgLoad}
                    ref={faceImgElement}
                    alt=" from local storage"
                    className={classes.image}
                  ></img>
                </Grid>
                <Grid item className={classes.gridItem}>
                  <img
                    src={props.images[1]}
                    onLoad={onIdImgLoad}
                    ref={idImgElement}
                    alt=" from local storage"
                    className={classes.image}
                  ></img>
                </Grid>
              </Grid>
            ) : (
              <Grid container className={classes.imagesContainer}>
                <Grid item className={classes.gridItem}>
                  <Boundingbox
                    image={props.images[0]}
                    boxes={[
                      [
                        //The bounding box for face recieved from the api is a ratio of the full height and width
                        // So multiply it with width and height to get the correct result,
                        //props.box=[Left, Top, Width, Height], dimensionsFaceImage= [height, width]
                        props.bBox[0][0] * dimensionsFaceImage[1],
                        props.bBox[0][1] * dimensionsFaceImage[0],
                        props.bBox[0][2] * dimensionsFaceImage[1],
                        props.bBox[0][3] * dimensionsFaceImage[0],
                      ],
                    ]}
                    options={params.options}
                  >
                   
                  </Boundingbox>
                </Grid>
                <Grid item className={classes.gridItem}>
                  <Boundingbox
                    image={props.images[1]}
                    boxes={[
                      [
                        //The bounding box for face recieved from the api is a ratio of the full height and width
                        // So multiply it with width and height to get the correct result,
                        //props.box=[Left, Top, Width, Height], dimensions= [height, width]
                        props.bBox[1][0] * dimensionsIdImage[1],
                        props.bBox[1][1] * dimensionsIdImage[0],
                        props.bBox[1][2] * dimensionsIdImage[1],
                        props.bBox[1][3] * dimensionsIdImage[0],
                      ],
                    ]}
                    options={params.options}
                  >
                
                  </Boundingbox>
                </Grid>
              </Grid>
            )
          }
        </Grid>

        <Grid item >
          {props.bBox[0][0] === null ? (

             

             
                <Button
                  variant="contained"
                  key={1}
                  color="primary"
                  component="label"
                  margin="auto"
                  disabled={false}
                  onClick={props.verify}
                  className={classes.button}
                >
                  Verify
                </Button>
         
           
          ) : (
            <div>
              <Button
                variant="contained"
                key={1}
                color="primary"
                component="label"
                disabled={false}
                onClick={props.retry}
                className={classes.button}
              >
                Retry
              </Button>
            </div>
          )}
        </Grid>

        <Grid item className={classes.gridItem}>
          <Box
            style={{
              borderStyle: "solid",
              borderColor: props.resultBorder,
              borderWidth: "2px",
              borderRadius: "10px",
              marginBottom: "1em",
              // backgroundColor: "#fcfcfc",
            }}
          >
            <Typography
              variant="body1"
              align="left"
              style={{
                textAlign: "center",
                color: "#525252",
                // paddingLeft: "15px",
                // paddingTop: "5px",
                // paddingBottom: "5px"
                padding: "5px 15px",
              }}
            >
              {props.resultText}
            </Typography>
          </Box>
        </Grid>

        <Grid item className={classes.gridItem}>
          {props.extractedText === null ? (
            <React.Fragment></React.Fragment>
          ) : (
            <Box
              style={{
                margin: "1em",
                // backgroundColor: "#fcfcfc",
              }}
            >
              {props.extractedText.TextDetections.map((item) => (
                <p>{item.DetectedText}</p>
              ))}
            </Box>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default VerifyPage;
